/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Area } from 'react-magnolia';
import Page from '../components/Page';
import { Breadcrumbs } from '../components/Common';
import styles from './styles.module.css';

const PageNode = ({ pageContext, path }) => {
  const { meta, content, siteUrl, isBreadcrumbs } = pageContext;

  return (
    <>
      {meta && (
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <meta name="keywords" content={meta.keywords} />
          <link rel="canonical" href={`${siteUrl}${path}`} />
        </Helmet>
      )}
      <Page>
        {isBreadcrumbs && <Breadcrumbs crumbs={[{ name: meta.title, path }]} />}
        {content && <Area {...content} className={styles.contentArea} />}
      </Page>
    </>
  );
};
export default PageNode;
